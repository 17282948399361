// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Dil Seçin"
  },
  SLOGAN: "Ödeme Almanın Yeni Yolu",
  MENU: {
    NEW: "Yeni",
    EDIT: "Düzenle",
    ACTIONS: "İşlemler",
    CREATE_POST: "Yeni yazı oluştur",
    PAGES: "Sayfalar",
    FEATURES: "Özellikler",
    APPS: "Uygulamalar",
    GREET: "Merhaba",
    BACK: "Geri"
  },
  ROUTES: {
    DASHBOARD: "Genel Görünüm",
    HOME: "Ana Sayfa",
    SALES_MANAGEMENT: "Satış Yönetimi",
    ALL_SALES: "Tüm Satışlar",
    NEW_SALE: "Yeni Satış",
    QR_SALE: "QR Kod İle Satış Oluştur",
    SALE_DETAILS: "Satış Detayı",
    WALLET_MANAGEMENT: "Cüzdan Yönetimi",
    WALLETS: "Cüzdanlar",
    WITHDRAWALS: "Para Çekme İşlemleri",
    PERSONNEL_MANAGEMENT: "Personel Yönetimit",
    PERSONNEL: "Tüm Personel",
    NEW_PERSONNEL: "Yeni Personel",
    USERS: "Kullanıcılar",
    PROFILE: "Profil",
    COMPANY: "Firma Ayarları",
    SETTINGS: "Genel Ayarlar",
    ROLES: "Roller",
    LOGS: "Loglar",
    LOGOUT: "Çıkış",
    CHANGE_PASSWORD: "Şifre Değiştir",
    CORPORATE: "Kurumsal",
    COMPANIES: "Firmalar",
    BRANCHES: "Şubeler",
    BRANCH: "Şube",
    MERCHANT_BRANCH_REPORT: "Şube Rapor Listesi",
    SALES_REPRESENTATIVES: "Satış Temsilcileri",
    NEW_REPRESENTATIVE: "Yeni Temsilci",
    POS_LIST: "El Terminali Listesi",
    REFUNDED_SALES: "İade Edilmiş Satışlar",
    WALLET: "Cüzdan",
    MERCHANTS: "Bayiler"
  },
  FORM: {
    ID: "Id",
    YES: "Evet",
    NO: "Hayır",
    OK: "Tamam",
    TRY_AGAIN: "Lütfen tekrar deneyin.",
    SAVE: "Kaydet",
    UPDATE: "Güncelle",
    SUBMIT: "Gönder",
    CANCEL: "İptal",
    CONFIRM: "Onaylıyorum",
    EMAIL: "E-Posta",
    FIRST_NAME: "Ad",
    LAST_NAME: "Soyad",
    FULL_NAME: "Ad soyad",
    PASSWORD: "Parola",
    PASSWORD_CONFIRM: "Parola Tekrar",
    PHONE: "Telefon",
    LOGIN_WITH_GOOGLE: "Google İki Adımlı Doğrulama İle Giriş Yapın",
    GOOGLE_AUTHENTICATOR: "Google Authenticator",
    GOOGLE_SCAN_MESSAGE1: "Karekodu",
    GOOGLE_SCAN_MESSAGE2: "ile tarayın",
    APP: "app",
    CLICK: "Tıklayın",
    WHEN_DONE: "Onaylandığında",
    PHONE_OR_EMAIL: "Telefon veya Eposta",
    CONFIRM_PASSWORD: "Parolayı Doğrulayın",
    USERNAME: "Kullanıcı adı",
    SUCCESS_TITLE: "İşlem Başarılı",
    SUBMITTED: " başarıyla kaydedildi",
    UPDATED: " başarıyla güncellendi",
    DELETED: " başarıyla silindi",
    ERROR_TITLE: "Hata!",
    ERROR: "Bir Hata Oluştu.",
    PLEASE_SELECT: "Seçiniz",
    PAYMENT_UNIT: "Ödeme Birimi",
    POS_NUMBER: "El Terminali Numarası",
    SELECT_A_PAYMENT_UNIT: "Ödeme birimi seçiniz",
    SELECT_A_CURRENCY_UNIT: "Para Birimini Seçiniz",
    ASSIGNMENT_CONFIRMATION: "Aktarma Onayı"
  },
  AUTH: {
    GENERAL: {
      OR: "Ya da",
      SUBMIT_BUTTON: "Gönder",
      NO_ACCOUNT: "Hesabınız yok mu?",
      SIGNUP_BUTTON: "Kayıt ol",
      FORGOT_BUTTON: "Parolamı Unuttum",
      BACK_BUTTON: "Geri",
      PRIVACY: "Gizlilik",
      LEGAL: "Yasal",
      CONTACT: "İletişim"
    },
    LOGIN: {
      TITLE: "Giriş",
      BUTTON: "Giriş",
      WELCOME: "Hoş Geldiniz",
      NEW_HERE: "Yeni misiniz?",
      CREATE_ACCOUNT: "Yeni Hesap Oluştur",
      LOGIN: "Giriş Yap",
      OTP_VERIFICATION: "OTP Doğrulaması",
      TWO_FA: "2 Aşamalı Doğrulama",
      TWO_FA_GOOGLE: "Google ile 2 Aşamalı Doğrulama",
      TWO_FA_MESSAGE:
        "Lütfen giriş yapmak için telefonunuza ya da elektronik posta adresinize gelen doğrulama kodunu giriniz",
      TWO_FA_GOOGLE_MESSAGE:
        "Lütfen giriş yapmak için Google Authentıcator uygulamasından alacağınız doğrulama kodunu giriniz",
      VERIFICATION_CODE: "Doğrulama Kodunu Giriniz",
      SEND_AGAIN: "Tekrar Gönder",
      LOADING_MESSAGE_TITLE: "Biraz Bekleteceğiz",
      LOADING_MESSAGE_SUBTITLE: "Bilgilerinizi Çekiyoruz..."
    },
    FORGOT: {
      TITLE: "Parolanızı mı unuttunuz?",
      DESC: "Parolanızı sıfırlamak için e-posta adresinizi giriniz",
      SUCCESS:
        "Şifre sıfırlama talimatları belirtilen email adresine başarıyla gönderildi.",
      INVALID_ATTEMPT: "Bu bilgilere ait kullanıcı bulunamadı."
    },
    REGISTER: {
      TITLE: "Kayıt ol",
      DESC: "Yeni hesap oluşturmak için bilgilerinizi giriniz",
      AGREEMENT_TEXT: "kabul ediyorum",
      AGREEMENT_LINK: "Şartları ve Koşulları",
      SUCCESS: "Hesabınız başarıyla kaydedildi."
    },
    VALIDATION: {
      INVALID: "{name} geçersiz",
      REQUIRED: "{name} gerekli alandır",
      MIN_LENGTH: "{name} en az {min} olmalıdır",
      AGREEMENT_REQUIRED: "Şartlar ve Koşulların kabul edilmesi gerekmektedir",
      NOT_FOUND: "İstenen {name} bulunamadı",
      INVALID_LOGIN: "Giriş bilgisi yanlıştır",
      REQUIRED_FIELD: "Zorunlu alan",
      MIN_LENGTH_FIELD: "En az karakter:",
      MAX_LENGTH_FIELD: "En fazla karakter:",
      INVALID_FIELD: "Alan geçersiz",
      PASSWORD_DONT_MATCH: "Şifreler uyuşmuyor."
    }
  },
  LIST: {
    CREATED_AT: "Kayıt",
    UPDATED_AT: "Son güncelleme"
  },
  USERS: {
    DELETED_USERS: "Silinmiş Kullanıcılar",
    NEW_USER: "Yeni Kullanıcı"
  },
  COMPANY: {
    COMPANY: "Firma",
    BRANCH: "Şube",
    NEW_COMPANY: "Yeni Firma Ekle",
    NEW_BRANCH: "Yeni Şube Ekle",
    SALES_REPRESENTATIVE: "Satış Temsilcisi",
    USER: "Kullanıcı",
    TITLE: "Ünvan",
    TAX_ADMINISTRATION: "Vergi Dairesi",
    TAX_CITY: "Vergi Dairesi Şehir",
    TAX_NUMBER: "Vergi Numarası",
    QUICK_SALE: "Hızlı Satış",
    MAIL_INFO_MESSAGE:
      "Açılan Kullanıcı kimlik bilgileri bu adrese gönderilecektir.",
    domestic_transfer_infos: "Yurtiçi Transfer Bilgileri",
    overseas_transfer_infos: "Yurtdışı Transfer Bilgileri",
    bank: "Banka",
    account_name: "Hesap Adı",
    account_type: "Hesap Tipi",
    account_no: "Hesap Numarası",
    buyer: "Alıcı Bilgisi(Kişi ya da Firma Adı)",
    city: "Şehir",
    swift_code: "Swift Kodu",
    update_payment_info: "Ödeme Bilgilerini Güncelle",
    NEW_MERCHANT: "Yeni Bayi Ekle"
  },
  PAGES: {
    SALES_MANAGEMENT: {
      ORDER_DETAILS: {
        SALE_DETAILS: "Satış Detayları",
        DELETE_ORDER: "Satışı Sil",
        ORDER_INFOS: "Sipariş Bilgileri",
        ORDER_ID: "Sipariş Kimliği",
        PAYMENT_IN_FIAT: "Ödeme Fiat olarak yapılacaktır",
        DOWNLOAD_BANK_RECEIPT: "Dekont İndir"
      },
      LINK_SALES: {
        NEW_SALE: "Link İle Satış Yap",
        ORDER_INFO: "Sipariş Bilgileri",
        ORDER_AMOUNT: "Sipariş Tutarı",
        COMMISSION_RATE: "Komisyon Oranı",
        DEDUCT_COMMISSION: "Komisyon oranı sipariş tutarından düşülsün mü?",
        COMMISSION_AMOUNT: "Komisyon Tutarı",
        ORDER_TOTAL: "Toplam Sirapiş Tutarı",
        CREATE_LINK: "Link Oluştur",
        ORDER_IDENTITY: "Sipariş Kimliği",
        TOTAL_AMOUNT: "Toplam Tutar",
        TOTAL_QUANTITY: "Toplam Miktar",
        WALLET: "Cüzdan Adresi",
        TRANSACTION_STATUS: "İşlem Durumu",
        TRANSACTION_SUCCESS: "İşlem Başarılı",
        TRANSACTION_ERROR: "İşlem Başarısız",
        ORDERS_COUNT: "{count} Sipariş",
        COMPLETED_ORDERS_AMOUNT: "Tamamlanan Sipariş Tutarları",
        COMMISSION_PAYER: "Komisyon Ödeyen"
      },
      LIST: {
        ORDER_ID: "Sipariş No",
        PRICE: "Fiyat",
        COMMISSION: "Komisyon",
        TOTAL: "Toplam",
        STATUS: "Durum",
        DATE: "Tarih",
        SALE: "Satış",
        PENDING: "Beklemede",
        COMPLETED: "Tamamlandı",
        PROCESSING: "İşleniyor",
        FAILED: "İşlenemedi",
        CANCELLED: "İptal Edildi",
        NOT_ENOUGH: "Eksik Ödeme",
        GIVING_BACK: "Geri Ödeme",
        ALL_DATES: "Tüm tarihler",
        SELECT_START_DATE: "Başlangıç tarihini seçin",
        SELECT_END_DATE: "Bitiş tarihini seçin",
        EXPORT_TO_PDF: "PDF",
        EXPORT_TO_EXCEL: "EXCEL",
        CRYPTO_CURRENCY_CODE: "Kripto para",
        CRYPTO_QUANTITY: "Kripto Miktarı",
        CRYPTO_QUANTITY_PAID: "Ödenen Kripto Miktarı",
        PLATFORM: "Platform",
        SELECTED_CURRENCY_CODE: "Kripto",
        PAYMENT_STATE_NAME: "Ödeme Durumu",
        PAYMENT_UNIT_NAME: "Ödeme Birimi",
        ORDER_NUMBER: "Sipariş Numarası",
        AFFILIATED_COMPANIES: "Bağlı Firmalar",
        LEVELS: {
          DISTRIBUTOR: "Distribütör",
          MERCHANT: "Bayi",
          BRANCH: "Şube"
        },
        PAYMENT_STATE: "Bayi Ödemesi",
        PAYMENT_STATES: {
          1: "Beklemede",
          2: "Yapılmakta",
          3: "Tamamlandı",
          4: "İptal edildi"
        },
        ORDER_STATUSES: {
          0: "Beklemede",
          1: "Tamamlandı",
          2: "Yapılmakta",
          3: "Başarısız",
          4: "İptal edildi",
          5: "Yetersiz bakiye",
          6: "Geri ödeme",
          7: "Gönderildi",
          8: "İşlem alındı",
          9: "Kuyrukta",
          10: "Yetkilendirme bekleniyor",
          11: "Reddedildi",
          12: "Engellendi"
        },
        PAYMENT_UNIT: "Ödeme Birimi",
        REFUND_PAYMENT: "İade Et",
        REFUND_WARNING:
          "İade işlemini gerçekleştirmek istediğinize emin misiniz?",
        REFUND_MODAL_INFO:
          "İade edilecek cüzdan adresini girin veya QR kodunu okumak için kamera butonuna tıklayın.",
        CUSTOMER: "Müşteri",
        SERVICE_COMMISSION: "Hizmet Komisyonu"
      },
      POS: {
        POS: "El Terminali",
        SERIAL: "Seri No",
        SYSTEM_DEFINED: "Sistem tanımlı",
        POS_SETUP: "El Terminali Ayarları",
        DELIVERED: "Teslimat",
        HAS_DELIVERED: "Teslim edildi mi?",
        REASON: "Sebep",
        UNDELIVERED_REASON: "Teslim edilmeme sebebi",
        DELIVERY_FORM: "Teslim formu",
        PASS: "Geçiş",
        POS_DESCRIPTION1: "El Terminali Tanım 1",
        POS_DESCRIPTION2: "El Terminali Tanım 2",
        SELECTED_POS_COUNT: "Seçilen El Terminali:",
        ASSIGN_TO_DISTRIBUTOR: "Distribütore Aktar",
        ASSIGN_TO_MERCHANT: "Bayiye Aktar",
        ASSIGN_TO_BRANCH: "Şubeye Aktar"
      },
      QR_SALE: {
        NEW_SALE_WITH_QR_CODE: {
          TITLE: "QR Kod İle Satış Yap",
          CUSTOMER_INFO: "Müşteri Bilgileri",
          SUCCESS_TITLE: "Ödemeniz Alınmıştır.",
          SUCCESS_SUBTITLE: "Teşekkür ederiz.",
          ERROR_SUBTITLE: "Lütfen tekrar deneyın.",
          SCAN_QR_CODE: "QR Kod Tarama",
          MIRACLE_CUSTOMER: "Miracle Müşterisi",
          NOT_MIRACLE_CUSTOMER: "Miracle Müşt. Değil",
          AN_ERROR_HAS_OCCURRED: "Bir hata oluştu.",
          TRY_AGAIN: "Tekrar Dene",
          ALL_ORDERS: "Tüm Siparişler",
          NEW_ORDER: "Yeni Satış Oluştur"
        },
        ORDER_COMPLETED: "Ödeme alındı.",
        ORDER_AMOUNT_IS: "İşlem Tutarı",
        MISSING_ORDER_PAYMENT: "Eksik ödeme yapıldı.",
        ORDER_CRYPTO_AMOUNT_IS: "Kripto Tutarı",
        nexusCreatePaymentWarning:
          "Ödeme yaptıysanız lütfen işlem sonucu gelene kadar bekleyiniz.",
        cancel_transaction: "İşlemi İptal Et",
        cancel_transaction_warning:
          "Satış işlemini iptal etmek istediğinize emin misiniz?",
        active_wallet_address_not_found_please_try_again_later:
          "Aktif bir cüzdan adresi bulunamadı. Lütfen daha sonra tekrar deneyiniz."
      },
      PROFILE: {
        PROFILE_PAGE: "Profil Sayfası"
      }
    },
    DASHBOARD: {
      CRYPTO: "Kripto",
      CURRENCY: "Para Birimi",
      LATEST_TRANSACTIONS: "Son İşlemler",
      CRYPTO_AMOUNT: "Tutar",
      MONTHLY_ORDER_STATUS: "Aylık Sipariş Durumu",
      CURRENCY_TYPE: "Döviz Cinsi",
      TOTAL_TRANSACTION_AMOUNT: "Toplam İşlem Tutarı",
      AMOUNT_PAID: "Ödenmiş Tutar",
      AMOUNT_AWAITING_PAYMENT: "Ödenmesi Bekleyen Tutar",
      TOTAL_PROFIT: "Toplam Kazanç"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Seçili kayıt adedi: ",
      ALL: "HEPSİ",
      SUSPENDED: "Durduruldu",
      ACTIVE: "Aktif",
      PASSIVE: "Pasif",
      FILTER: "Filtre",
      BY_STATUS: "Duruma göre",
      BY_TYPE: "Tipe göre",
      BUSINESS: "İş",
      INDIVIDUAL: "Bireysel",
      SEARCH: "Arama",
      IN_ALL_FIELDS: "tüm alanlarda"
    },
    ECOMMERCE: "eTicaret",
    CUSTOMERS: {
      CUSTOMERS: "Müşteriler",
      CUSTOMERS_LIST: "Müşteri List",
      NEW_CUSTOMER: "Yeni Müşteri",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Müşteriyi Sil",
        DESCRIPTION: "Bu müşteriyi kalıcı olarak silmek istiyor musunuz?",
        WAIT_DESCRIPTION: "Müşteri siliniyor...",
        MESSAGE: "Müşteri silindi"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Müşterileri Sil",
        DESCRIPTION: "Seçili müşterileri kalıcı olarak silmek istiyor musunuz?",
        WAIT_DESCRIPTION: "Müşteriler siliniyor...",
        MESSAGE: "Seçili müşteriler silindi"
      },
      UPDATE_STATUS: {
        TITLE: "Seçili müsteriler için durum güncellendi",
        MESSAGE: "Seçili müşterilerin durumları başarıyla güncellendi"
      },
      EDIT: {
        UPDATE_MESSAGE: "Müşteri güncellendi",
        ADD_MESSAGE: "Müşteri kaydı oluşturuldu"
      }
    }
  },
  VALIDATION: {
    NUMERIC: "Sadece numerik değerler kabul edilir.",
    MIN_VALUE: "{name} minimum değer {min} olmalı",
    ONLY_INTEGERS: "Yalnızca tamsayılar geçerlidir.",
    MAX_LENGTH: "En fazla {num} karakterden oluşabilir.",
    REQUIRED_FIELD: "Zorunlu alan",
    EMAIL_NOT_VALID: "Geçersiz email adresi.",
    DUPLICATED_VALUES: "Aynı {field} birden fazla defa girildi.",
    FILE_SIZE_LESS_THAN: "Dosya boyutu {size}'tan küçük olmalıdır.",
    ACCEPTED_FILE_TYPES: "Kabul edilen dosya türleri; {types}"
  },
  COMMON: {
    ACTIVATE: "Aktif Et",
    DEACTIVATE: "Pasif Et",
    MAKE_PASSIVE: "Pasif Et",
    NO_DATA_AVAILABLE: "Veri bulunamadı.",
    INFORM: "Bilgilendirme",
    are_you_sure_you_want_to_perform:
      "Bu işlemi gerçekleştirmek istediğinizden emin misiniz?",
    operation_performed: "İşlem başarıyla gerçekleştirildi.",
    ACCESS: "Erişim",
    GRANT_ACCESS: "Erişim İzni Ver",
    RESTRICT_ACCESS: "Erişimi Kısıtla",
    access_granted: "Erişim Var",
    access_restricted: "Erişim Kısıtlı",
    continue: "Devam Et",
    close_window_and_cancel_warning:
      "Pencereyi kapatarak bu işlemi iptal etmek istediğinize emin misiniz?",
    payment: "Ödeme",
    network: "Ağ",
    general: "Genel",
    hide_low_balances: "Düşük Bakiyeleri Gizle",
    refresh: "Yenile",
    reminder: "Hatırlatma",
    previous: "Önceki",
    next: "Sonraki",
    browse: "Gözat",
    browse_files: "Dosyalara Gözat"
  },
  virtual_pos_api_doc: {
    title: "Sanal Pos Döküman",
    example: "Örnek",
    intro: "Giriş",
    intro_desc:
      // eslint-disable-next-line max-len
      '<div class="mt-20"><h3>1- E-ticaret gibi sistemlerin ödeme sistemi entegresyonu yapabilmeleri için <a href="https://panel.miraclepay.com/"> MiraclePay</a> den kayıt olmaları gerekmektedir. </h3></div><div class="mt-20"><h3>2- Kayıt sonrası ana kullanıcı sistemde Distribütor olarak tutulmaktadır.</h3></div><div class="mt-20"><h3>3- Her distribütör tedarikçilerini kendi altında brach olarak MiraclePay\' e kayıt etmeleri gerekmektedir.</h3></div><div class="mt-20"><h3>4- Ödeme esnasında kullanıcı bilgileri ile sistemden token alınıp, toplam ödenecek tutar ile otomatik sipariş oluşturulmalıdır.</h3></div><div class="mt-20"><h3>5- Sipariş sonrasında Crypto para birimi seçilip QR ile ödeme yapılacaktır.</h3></div><div class="mt-20"><h3>6- QR ile ödeme yapacak olan son kullanıcının Miracle Cash&More (MCM) uygulamasına kayıtlı olması gerekmektedir.</h3></div><div class="mt-20"><h3>7- Ödeme yapacak olan son kullanıcı QR bilgisine MCM den erişebilecektir..</h3></div><div class="mt-20"><h3>8- QR bilgisini bilgisayarın kamerası okuyacak şekilde tasarlanmalı. QR okuyucu ile Ödeme aşamasındaki gönderilen data içerisinde hash\'e karşılık gelecek şekilde gönderilmesi gerekmektedir..</h3></div>',
    intro_non_miracle_customer_desc:
      // eslint-disable-next-line max-len
      '<div class="mt-20"><h3>1- E-ticaret gibi sistemlerin ödeme sistemi entegresyonu yapabilmeleri için <a href="https://panel.miraclepay.com/"> MiraclePay</a> den kayıt olmaları gerekmektedir. </h3></div><div class="mt-20"><h3>2- Kayıt sonrası ana kullanıcı sistemde Distribütor olarak tutulmaktadır.</h3></div><div class="mt-20"><h3>3- Her distribütör tedarikçilerini kendi altında brach olarak MiraclePay\' e kayıt etmeleri gerekmektedir.</h3></div><div class="mt-20"><h3>4- Ödeme esnasında kullanıcı bilgileri ile sistemden token alınıp, toplam ödenecek tutar ile otomatik sipariş oluşturulmalıdır.</h3></div><div class="mt-20"><h3>5- Sipariş sonrasında Crypto para birimi seçilip \'Miracle Müşterisi Değil\' tıkladıktan sonra açılan modaldaki cüzdan adresine müşterinin kendi crypto borsasından ödeme yapılması beklenir.</h3></div>',
    auth_desc: "Token alabilmek için kullanılması gereken test url",
    create_branch: "Branch Oluşturma",
    create_branch_desc: "Branch oluşturabilmek için kullanılması gereken url",
    create_order: "Sipariş Oluşturma",
    create_order_desc: "Sipariş oluşturabilmek için kullanılması gereken url",
    payment_stage: "Ödeme Aşaması",
    payment_stage_desc: "Ödemeyi tamamlamak için gerekli Url",
    payment_stage_desc_2:
      "<br /><h3>Detay</h3><br /><h3>{id} olarak tanımlanmış olan alana sipariş numarası eklenmelidir</h3><h3>Hash olarak tanımlanmış alan QR bilgisinin string olarak karşılığıdır.</h3>",
    crypto_currencies: "Crypto Listesi",
    crypto_currencies_desc:
      "Crypto listesini alabilmek için kullanılması gereken test url",
    getMe: "Aktif Kullanıcı",
    getMe_desc:
      "Aktif kullanıcı bilgilerini alabilmek için kullanılması gereken test url",
    convert: "Dönüştürme",
    convert_desc: "Parabirimi tutarının kripto miktarı karşılığını veren url",
    customer: "Müşteri",
    get_company_order: "Firma Siparişi",
    get_company_order_desc: "Sipariş ID ile firma sipariş detayını veren url.",
    delete_wallet_address: "Cüzdan Adresi Silme",
    delete_wallet_address_desc:
      "Crypto gönderimi yapılmadıysa var olan cüzdan adresi askıya düşürülecektir. Fakat ödeme yapılması durumunda kullanıcıya dip not olarak yansıtılması gerekmektedir. Ödeme yapıp addres silme işlemi yapılırsa ödeme takibi sonlanacaktır. Ödeme yapıldıysa çözüm birimlerine iletilip takibi yapılması gerekmektedir.",
    simulation_link: "Simülasyon Linki",
    crypto_currencies_desc_2:
      "<br /><h3>'id', {uri}me endpointindeki parent objesinin id değeridir.</h3>"
  },
  devices: {
    pos: "Pos",
    vp: "Sanal Pos",
    pnl: "Panel",
    wc: "WooCommerce"
  },
  qrcodeReader: {
    google_chrome_warning:
      "Google Chrome kullanıyorsanız, Qr tarayıcı web kameranıza tekrar erişmeye çalışmaz. Daha önce erişimi reddettiyseniz, Google Chrome menüsündeki Gizlilik ve güvenlik bölümünden ayarları manuel olarak değiştirmeniz gerekecektir.",
    NotAllowedError:
      "Ödeme işlemine devam etmek için lütfen tarayıcınızın web kamerası erişimini kontrol edin.",
    NotFoundError:
      "Ödeme işleminin devam etmesi için bir web kamerası gerekir. Uygun web kamerası aygıtı bulunamadı.",
    NotSupportedError: "Desteklenmeyen cihaz hatası.",
    NotReadableError:
      "Ödeme işlemine devam etmek için lütfen tarayıcınızın web kamerası erişimini kontrol edin.",
    OverconstrainedError:
      "Ödeme işleminin devam etmesi için bir web kamerası gerekir. Uygun web kamerası aygıtı bulunamadı.",
    StreamApiNotSupportedError:
      "Tarayıcınızın özellikleri desteklenmiyor olabilir."
  },
  kyc_kyb: {
    idCard: "Kimlik Kartı",
    commerceNumber: "Ticaret Odası Numarası",
    taxNumber: "Vergi Numarası",
    sourceOfFund: "Fon kaynağı",
    files_companyActivities:
      "Şirket faaliyet listesi ve faaliyet gösterilen ülkelerin listesi",
    files_orgChart: "Tüm kuruluşlar için organizasyon şeması",
    files_ubo: "Nihai Yararlanıcı Kayıt Belgesi",
    submitted: "İsteğiniz başarıyla gönderildi.",
    rejected_status_text:
      "KYC-KYB isteğinizniz reddedildi, lütfen bilgilerinizi gözden geçirin ve dosyalarınızı tekrar ekleyin.",
    waiting_status_text: "KYC-KYB isteğiniz inceleniyor.",
    id_cards_including_ubos:
      "Direktörler ve Ortakların Kimlik Kartları (Nihai Yararlanıcılar dahil)"
  }
};
