// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  SLOGAN: "New Way of Payment Collecting",
  MENU: {
    NEW: "New",
    EDIT: "Edit",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    GREET: "Hello",
    BACK: "Back"
  },
  ROUTES: {
    DASHBOARD: "Dashboard",
    HOME: "Home",
    SALES_MANAGEMENT: "Sales Management",
    ALL_SALES: "All Sales",
    NEW_SALE: "New Sale",
    QR_SALE: "New Sale with QR Code",
    SALE_DETAILS: "Sale Details",
    WALLET_MANAGEMENT: "Wallet Management",
    WALLETS: "Wallets",
    WITHDRAWALS: "Withdrawals",
    PERSONNEL_MANAGEMENT: "Personnel Management",
    PERSONNEL: "Tüm Personel",
    NEW_PERSONNEL: "New Personnel",
    USERS: "Users",
    PROFILE: "Profile",
    COMPANY: "Company",
    SETTINGS: "Settings",
    ROLES: "Roles",
    LOGS: "Logs",
    LOGOUT: "Logout",
    CHANGE_PASSWORD: "Change Password",
    CORPORATE: "Corporate",
    COMPANIES: "Companies",
    BRANCHES: "Branches",
    BRANCH: "Branch",
    MERCHANT_BRANCH_REPORT: "Merchant Report List",
    SALES_REPRESENTATIVES: "Sales Representatives",
    NEW_REPRESENTATIVE: "New Representative",
    POS_LIST: "Hand Terminals",
    REFUNDED_SALES: "Refunded Sales",
    WALLET: "Wallet",
    MERCHANTS: "Merchants"
  },
  FORM: {
    ID: "Id",
    YES: "Yes",
    NO: "No",
    OK: "OK",
    TRY_AGAIN: "Try Again",
    SAVE: "Save",
    UPDATE: "Update",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    EMAIL: "Email",
    FIRST_NAME: "Name",
    LAST_NAME: "Surname",
    FULL_NAME: "Name familyname",
    PASSWORD: "Password",
    PASSWORD_CONFIRM: "Password Confirmation",
    PHONE: "Phone",
    LOGIN_WITH_GOOGLE: "Login with Google Two Factor Authentication",
    GOOGLE_SCAN_MESSAGE1: "Scan QR Code through",
    GOOGLE_SCAN_MESSAGE2: "",
    CLICK: "Click",
    WHEN_DONE: "when done",
    PHONE_OR_EMAIL: "Phone or Email",
    CONFIRM_PASSWORD: "Confirm Password",
    USERNAME: "Username",
    SUCCESS_TITLE: "Successful Operation",
    SUBMITTED: " successfully saved",
    UPDATED: " successfully updated",
    DELETED: " successfully deleted",
    ERROR_TITLE: "Error",
    ERROR: "An Error Occured.",
    PLEASE_SELECT: "Select an option",
    PAYMENT_UNIT: "Payment Unit",
    POS_NUMBER: "Hand Terminal Number",
    SELECT_A_PAYMENT_UNIT: "Select a payment unit",
    SELECT_A_CURRENCY_UNIT: "Select a currency unit",
    ASSIGNMENT_CONFIRMATION: "Assignment Confirmation"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
      WELCOME: "Welcome",
      NEW_HERE: "New Here?",
      CREATE_ACCOUNT: "Create New Account",
      LOGIN: "Login",
      OTP_VERIFICATION: "OTP Verification",
      TWO_FA: "Two Factor Authentication",
      TWO_FA_GOOGLE: "Two Factor Authentication with Google",
      TWO_FA_MESSAGE:
        "Please enter the code sent to your phone or email to login",
      TWO_FA_GOOGLE_MESSAGE:
        "Please enter the code from Google Authenticator app to login",
      VERIFICATION_CODE: "Enter verification code",
      SEND_AGAIN: "Send Again",
      LOADING_MESSAGE_TITLE: "Hang On",
      LOADING_MESSAGE_SUBTITLE: "Gathering Your Info..."
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS:
        "Password reset instructions have been successfully sent to the specified email address.",
      INVALID_ATTEMPT: "There is no user with this email address."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      AGREEMENT_TEXT: "I Agree the",
      AGREEMENT_LINK: "terms and conditions",
      SUCCESS: "Your account has been successfuly registered."
    },
    VALIDATION: {
      INVALID: "{name} is not valid",
      REQUIRED: "{name} is required",
      MIN_LENGTH: "{name} minimum length is {min}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {name} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
      PASSWORD_DONT_MATCH: "Passwords do not match."
    }
  },
  LIST: {
    CREATED_AT: "Created at",
    UPDATED_AT: "Updated at"
  },
  USERS: {
    DELETED_USERS: "Deleted Users",
    NEW_USER: "New User"
  },
  COMPANY: {
    COMPANY: "Company",
    BRANCH: "Branch",
    NEW_COMPANY: "Add New Company",
    NEW_BRANCH: "Add New Branch",
    SALES_REPRESENTATIVE: "Sales Representative",
    USER: "User",
    TITLE: "Title",
    TAX_ADMINISTRATION: "Tax Administration",
    TAX_CITY: "Tax Office City",
    TAX_NUMBER: "Tax Number",
    QUICK_SALE: "Quick Sale",
    MAIL_INFO_MESSAGE: "Generated user credentials will be sent to this email.",
    domestic_transfer_infos: "Domestic Transfer Informations",
    overseas_transfer_infos: "Overseas Transfer Informations",
    bank: "Bank",
    account_name: "Account Name",
    account_type: "Account Type",
    account_no: "Account No",
    buyer: "Buyer Info(Person or Company Name)",
    city: "City",
    swift_code: "Swift Code",
    update_payment_info: "Update Payment Info",
    NEW_MERCHANT: "Add New Merchant"
  },
  PAGES: {
    SALES_MANAGEMENT: {
      ORDER_DETAILS: {
        SALE_DETAILS: "Sale Details",
        DELETE_ORDER: "Delete Order",
        ORDER_INFOS: "Order Informations",
        ORDER_ID: "Order ID",
        PAYMENT_IN_FIAT: "Payment will be made in Fiat",
        DOWNLOAD_BANK_RECEIPT: "Download Receipt"
      },
      LINK_SALES: {
        NEW_SALE: "New Sale with Link",
        ORDER_INFO: "Order Info",
        ORDER_AMOUNT: "Order Amount",
        COMMISSION_RATE: "Commission Rate",
        DEDUCT_COMMISSION: "Deduct Commission from Order Amount?",
        COMMISSION_AMOUNT: "Commission Amount",
        ORDER_TOTAL: "Order Total",
        CREATE_LINK: "Create Link",
        ORDER_IDENTITY: "Order Identity",
        TOTAL_AMOUNT: "Total Amount",
        TOTAL_QUANTITY: "Total Quantity",
        WALLET: "Wallet Address",
        TRANSACTION_STATUS: "Transaction Status",
        TRANSACTION_SUCCESS: "Transaction Success",
        TRANSACTION_ERROR: "Transaction Error",
        ORDERS_COUNT: "{count} Orders",
        COMPLETED_ORDERS_AMOUNT: "Completed Orders Amount",
        COMMISSION_PAYER: "Comission Payer"
      },
      LIST: {
        ORDER_ID: "Order id",
        PRICE: "Price",
        COMMISSION: "Comission",
        TOTAL: "Total",
        STATUS: "Status",
        DATE: "Date",
        SALE: "Sale",
        PENDING: "Pending",
        COMPLETED: "Completed",
        PROCESSING: "Processing",
        FAILED: "Failed",
        CANCELLED: "Cancelled",
        NOT_ENOUGH: "Not Enough",
        GIVING_BACK: "Giving Back",
        ALL_DATES: "All dates",
        SELECT_START_DATE: "Select start date",
        SELECT_END_DATE: "Select end date",
        EXPORT_TO_PDF: "PDF",
        EXPORT_TO_EXCEL: "EXCEL",
        CRYPTO_CURRENCY_CODE: "Crypto Currency",
        CRYPTO_QUANTITY: "Crypto Quantity",
        CRYPTO_QUANTITY_PAID: "Paid Crypto Quantity",
        PLATFORM: "PLATFORM",
        SELECTED_CURRENCY_CODE: "Currency Code",
        PAYMENT_STATE_NAME: "Payment State",
        PAYMENT_UNIT_NAME: "Payment Unit",
        ORDER_NUMBER: "Order Number",
        AFFILIATED_COMPANIES: "Affiliated Companies",
        LEVELS: {
          DISTRIBUTOR: "Distributor",
          MERCHANT: "Merchant",
          BRANCH: "Branch"
        },
        PAYMENT_STATE: "Merchant Payment",
        PAYMENT_STATES: {
          1: "Pending",
          2: "Processing",
          3: "Completed",
          4: "Cancelled"
        },
        ORDER_STATUSES: {
          0: "Pending",
          1: "Completed",
          2: "Processing",
          3: "Failed",
          4: "Cancelled",
          5: "Insufficient balance",
          6: "Refund",
          7: "Submitted",
          8: "Transaction received",
          9: "Queued",
          10: "Pending authorization",
          11: "Rejected",
          12: "Blocked"
        },
        PAYMENT_UNIT: "Payment Unit",
        REFUND_PAYMENT: "Refund",
        REFUND_WARNING: "Are you sure you want to perform the refund?",
        REFUND_MODAL_INFO:
          "Enter the wallet address to be refunded or press the camera button to read QR code.",
        CUSTOMER: "Customer",
        SERVICE_COMMISSION: "Service Commission"
      },
      POS: {
        POS: "Hand Terminal",
        SERIAL: "Serial No",
        SYSTEM_DEFINED: "System defined",
        POS_SETUP: "Hand Terminal Setup",
        DELIVERED: "Delivered",
        HAS_DELIVERED: "Has delivered",
        REASON: "Reason",
        UNDELIVERED_REASON: "Undelivery reason",
        DELIVERY_FORM: "Delivery form",
        PASS: "Pass",
        POS_DESCRIPTION1: "Hand Terminal Description 1",
        POS_DESCRIPTION2: "Hand Terminal Description 2",
        SELECTED_POS_COUNT: "Selected Hand Terminal:",
        ASSIGN_TO_DISTRIBUTOR: "Assign to Distributor",
        ASSIGN_TO_MERCHANT: "Assign to Merchant",
        ASSIGN_TO_BRANCH: "Assign to Branch"
      },
      QR_SALE: {
        NEW_SALE_WITH_QR_CODE: {
          TITLE: "New Sale with QR Code",
          CUSTOMER_INFO: "Customer Info",
          SUCCESS_TITLE: "Payment Received.",
          SUCCESS_SUBTITLE: "Thank you.",
          ERROR_SUBTITLE: "Please Try Again.",
          SCAN_QR_CODE: "Scan QR Code",
          MIRACLE_CUSTOMER: "Miracle Customer",
          NOT_MIRACLE_CUSTOMER: "Not Miracle Cust.",
          AN_ERROR_HAS_OCCURRED: "An error has occurred.",
          TRY_AGAIN: "Try Again",
          ALL_ORDERS: "All Orders",
          NEW_ORDER: "New Order"
        },
        ORDER_COMPLETED: "Order completed.",
        ORDER_AMOUNT_IS: "Transaction Amount",
        MISSING_ORDER_PAYMENT: "Missing order payment has been made.",
        ORDER_CRYPTO_AMOUNT_IS: "Crypto Amount",
        nexusCreatePaymentWarning:
          "If you've made the payment, please wait until the transaction result is received.",
        cancel_transaction: "Cancel Transaction",
        cancel_transaction_warning:
          "Are you sure you want to cancel this transaction?",
        active_wallet_address_not_found_please_try_again_later:
          "An active wallet address not found. Please try again later."
      },
      PROFILE: {
        PROFILE_PAGE: "Profile Page"
      }
    },
    DASHBOARD: {
      CRYPTO: "Crypto",
      CURRENCY: "Currency",
      LATEST_TRANSACTIONS: "Latest Transactions",
      CRYPTO_AMOUNT: "Amount",
      MONTHLY_ORDER_STATUS: "Monthly Order Status",
      CURRENCY_TYPE: "Currency Type",
      TOTAL_TRANSACTION_AMOUNT: "Total Transaction Amount",
      AMOUNT_PAID: "Amount Paid",
      AMOUNT_AWAITING_PAYMENT: "Amount Awaiting Payment",
      TOTAL_PROFIT: "Total Profit"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PASSIVE: "Passive",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  VALIDATION: {
    NUMERIC: "Accepts only numeric values.",
    MIN_VALUE: "{name} minimum value must be {min}",
    ONLY_INTEGERS: "Only integers are accepted.",
    MAX_LENGTH: "It can contain a maximum of {num} characters.",
    REQUIRED_FIELD: "Required field",
    EMAIL_NOT_VALID: "Email not valid.",
    DUPLICATED_VALUES: "You entered the same {field} more than once.",
    FILE_SIZE_LESS_THAN: "The file size must be less than {size}.",
    ACCEPTED_FILE_TYPES: "Accepted file types; {types}"
  },
  COMMON: {
    ACTIVATE: "Activate",
    DEACTIVATE: "Deactivate",
    MAKE_PASSIVE: "Make Passive",
    NO_DATA_AVAILABLE: "No data available.",
    INFORM: "Information",
    are_you_sure_you_want_to_perform:
      "Are you sure you want to perform this transaction?",
    operation_performed: "Operation performed successfully.",
    ACCESS: "Access",
    GRANT_ACCESS: "Grant Access",
    RESTRICT_ACCESS: "Restrict Access",
    access_granted: "Access Granted",
    access_restricted: "Access Restricted",
    continue: "Continue",
    close_window_and_cancel_warning:
      "Are you sure you want to cancel this transaction by closing the window?",
    payment: "Payment",
    network: "Network",
    general: "General",
    hide_low_balances: "Hide Low Balances",
    refresh: "Refresh",
    reminder: "Reminder",
    previous: "Previous",
    next: "Next",
    browse: "Browse",
    browse_files: "Browse Files"
  },
  virtual_pos_api_doc: {
    title: "Virtual-Pos Documentation",
    example: "Example",
    intro: "Intro",
    intro_desc:
      // eslint-disable-next-line max-len
      '<div class="mt-20"><h3>1- For E-Commerce integration to the payment system, <a href="https://panel.miraclepay.com/"> MiraclePay</a> registration must be completed. </h3></div><div class="mt-20"><h3>2- After registration, the main user is defined as a Distributor.</h3></div><div class="mt-20"><h3>3- Each Distributor must register their suppliers as a Branch under MiraclePay.</h3></div><div class="mt-20"><h3>4- During the payment, tokens should be taken from the system with the user information and an automatic order should be created with the total amount to be paid.</h3></div><div class="mt-20"><h3>5- After the order, crypto type will be selected and payment will be completed with QR.</h3></div><div class="mt-20"><h3>6- The customer must be registered to Miracle Cash&More (MCM) application.</h3></div><div class="mt-20"><h3>7- The customer can access the QR information through MCM.</h3></div><div class="mt-20"><h3>8- The PC camera should be able to scan the QR information. The data scanned by the QR and sent to the system must correspond to the hash in the data.</h3></div>',
    intro_non_miracle_customer_desc:
      // eslint-disable-next-line max-len
      '<div class="mt-20"><h3>1- For E-Commerce integration to the payment system, <a href="https://panel.miraclepay.com/"> MiraclePay</a> registration must be completed. </h3></div><div class="mt-20"><h3>2- After registration, the main user is defined as a Distributor.</h3></div><div class="mt-20"><h3>3- Each Distributor must register their suppliers as a Branch under MiraclePay.</h3></div><div class="mt-20"><h3>4- During the payment, tokens should be taken from the system with the user information and an automatic order should be created with the total amount to be paid.</h3></div><div class="mt-20"><h3>5- After the order, crypto type will be selected and then it will be pressed to \'Non Miracle Customer\' button after that it will be paid from the customer\'s wallet market to QR in the shown modal.</h3></div>',
    auth_desc: "The URL to get tokens",
    create_branch: "Branch Creation",
    create_branch_desc: "The URL to create a branch",
    create_order: "Order Creation",
    create_order_desc: "The URL to create an order",
    payment_stage: "Payment Stage",
    payment_stage_desc: "The URL to complete payment",
    payment_stage_desc_2:
      "<br /><h3>Details</h3><br /><h3>Order number must be added to the field defined as {id}</h3><h3>The field defined as a hash is the string equivalent of the QR information</h3>",
    crypto_currencies: "Crypto List",
    crypto_currencies_desc: "The URL to get the Crypto list",
    getMe: "Active User",
    getMe_desc: "The URL to get active user information",
    convert: "Convert",
    convert_desc:
      "The url to convert currency amount to crypto currency quantity",
    customer: "Customer",
    get_company_order: "Company Order",
    get_company_order_desc:
      "The URL which gives you company order response by order ID",
    delete_wallet_address: "Delete Wallet Address",
    delete_wallet_address_desc:
      "The current wallet address will be suspended if crypto sending is not performed. But if crypto sending performed the user must be informed. If payment and delete wallet address transactions performed both then payment tracking will be ended. But only If payment transaction done the solution units must be informed and follow up is a must.",
    simulation_link: "Simulation Link",
    crypto_currencies_desc_2:
      "<br /><h3>The 'id' is from {uri}me endpoint that's the value of parent object.</h3>"
  },
  devices: {
    pos: "Pos",
    vp: "Virtual Pos",
    pnl: "Panel",
    wc: "WooCommerce"
  },
  qrcodeReader: {
    google_chrome_warning:
      "If you are using Google Chrome, this prompt will not attempt to access your webcam again. If you previously denied access, you will need to manually change the settings from the Google Chrome menu, under the Privacy and security section.",
    NotAllowedError:
      "Please check your browser's webcam access to continue the payment process.",
    NotFoundError:
      "The payment process requires a webcam to proceed. No suitable webcam device was found.",
    NotSupportedError: "Not supported error.",
    NotReadableError:
      "Please check your browser's webcam access to continue the payment process.",
    OverconstrainedError:
      "The payment process requires a webcam to proceed. No suitable webcam device was found.",
    StreamApiNotSupportedError: "Browser seems to be lacking features."
  },
  kyc_kyb: {
    idCard: "ID Card",
    commerceNumber: "Chamber of Commerce number of all involved entities",
    taxNumber: "Tax Identification Number",
    sourceOfFund: "Source of funds",
    files_companyActivities:
      "A list of company activities and an overview of the countries with which the company cooperates",
    files_orgChart: "Org chart of all involved entitiets",
    files_ubo: "UBO register Extract",
    submitted: "Your request has been sent successfully.",
    rejected_status_text:
      "Your KYC-KYB has been denied please review your information and attach your files again.",
    waiting_status_text: "Your KYC-KYB request is in under review.",
    id_cards_including_ubos:
      "ID Card of Directors and Shareholders including UBO’s"
  }
};
